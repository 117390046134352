<template>
  <div class="home">
    <main>
    <h1 class="header_pdf">Freiwillige Feuerwehr Lunestedt</h1>
    <article>
      <h2>Wer ist die Freiwillige Feuerwehr</h2><p>
        Die Freiwillige Feuerwehr ist ein Zusammenschluss von Männern und Frauen, die es sich zur Aufgabe gemacht haben, unentgeltlich Menschen in Not und Gefahr zu helfen.
        Ihre Aufgaben lassen sich in vier Schlagworte zusammenfassen:
        </p><br>
      <div class="motto">
        <p class="stich">RETTEN, LÖSCHEN, BERGEN, SCHÜTZEN
        <br></p>
        <img src="@/components/images/Logo.gif" class="logo"/>
      </div>
      <h2>Was macht die Feuerwehr</h2><p>
        Die Feuerwehr ist entweder eine berufsmäßige oder freiwillige Organisation, 
        die i. d. R. von der betreffenden Gemeinde unterhalten wird. Das Aufgabenfeld umfasst Brände, 
        Unfälle, Überschwemmungen und Hilfeleistungen bei ähnlichen Ereignissen, d. h. Menschen, 
        Tiere und Sachwerte zu retten sowie die Umwelt zu schützen, zu löschen und zu bergen. 
        Der Menschenrettung kommt hierbei oberste Priorität zu.
        <br>
        Die Freiwillige Feuerwehr Lunestedt wurde 1972 gegründet. 
        Entstanden ist sie durch einen Zusammenschluss der Freiwilligen Feuerwehren Freschluneberg 
        (Gründungsjahr 1902) und Westerbeverstedt (Gründungsjahr 1905).
      <br></p>
      <img src="@/components/images/feuerwehrlunestedt.jpg" class="open_pdf"/>
      <h2 class="notruf">Notruf: <span class="nummer">112</span> Feuerwehr und Rettungsdienst</h2>
      <a href="https://www.feuerwehr-lunestedt.de/download/beitritt.pdf" download="beitritt.pdf">
        <button class="mitglieds">Beitrittserklärung</button>
      </a>
      </article>
</main>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      isVisible: false,
    }
  },
  components: {
  },
  methodes: {
  }
}
</script>
<style scoped>
.header_pdf {
  margin: auto;
  padding-bottom: 20px;
  padding-top: 20px;
}
.open_pdf {
  width: 50%;
  height: auto;
  margin-left: 25%;
}
@media(max-width:900px) {
  .open_pdf {
  width: 100%;
  margin-left: 0%;
}
.home {
  padding: 0;
}
}
.youthmain {
  margin-top: 20px;
}
.stich {
  font-weight: bold;
}
.logo {
  margin: auto;
  width: 10%;
  height: auto;
}
.bild_parent {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px;
  border-radius: 5px;
  border-color: var(--inverted-color);
  padding-bottom: 15px;
  margin-top: 10px;
}
.bilder {
  width: 20%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
button {
  border-radius: 5px;
  border-color: var(--inverted-color);
  background-color: transparent;
  font-size: 20px;
  color: var(--inverted-color);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}
button:hover {
  color: var(--complementary-color);
  transition: 0.3s;
}
h2 {
  color: var(--primary-color);
  margin-top: 50px;
}
.notruf {
  color: black;
}
h3 {
  margin-top: 50px;
}
.nummer {
  color: var(--primary-color);
  font-weight: bold;
}
.mitglieds {
  margin-top: 50px;
}
</style>